import type { RenderFullTree } from '../../types'

export function useSportList(renderFullTree: Ref<RenderFullTree[]>) {
  const route = useRoute()

  const querySportIds = computed(() => {
    let querySportId = route.query?.sportId ?? []
    if (!Array.isArray(querySportId)) {
      querySportId = [querySportId]
    }
    return querySportId
  })

  const filteredSportsByEntityIds = computed(() => {
    if (querySportIds.value.length) {
      return renderFullTree.value.filter((item) =>
        querySportIds.value.includes(item.sport.entityId),
      )
    }
    return renderFullTree.value
  })

  return {
    filteredSportsByEntityIds,
  }
}
